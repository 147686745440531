import { Route, Switch } from 'react-router-dom';

import BulkDownloadPage from '../BulkDownloadPage';
import HomePage from '../HomePage';
import NotFoundPage from '../NotFoundPage';
import ProteinDownloadPage from '../ProteinDownloadPage';
import SearchPage from '../SearchPage';
import SingleProteinPage from '../SingleProteinPage';

const reload = () => window.location.reload();

export default function Main() {
  return (
    <Switch>
      <Route exact path='/' component={HomePage}></Route>
      <Route exact path='/search' component={SearchPage}></Route>
      <Route exact path='/download/protein' component={ProteinDownloadPage}></Route>
      <Route exact path='/download/protein/:proteinWebPid' component={ProteinDownloadPage}></Route>
      <Route exact path='/download/bulk' component={BulkDownloadPage}></Route>
      <Route exact path='/proteins/:proteinWebPid' component={SingleProteinPage}></Route>
      <Route path='/LICENSE.txt' onEnter={reload} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}
