import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
  },
});

export default function NavigationBar() {
  const classes = useStyles();

  return (
    <Navbar collapseOnSelect expand='sm' bg='dark' variant='dark'>
      <Container className={classes.container}>
        <Navbar.Brand>EVE</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Link as={Link} exact='true' to="/">Home</Nav.Link>
            <Nav.Link as={Link} exact='true' to="/search">Search</Nav.Link>
            <NavDropdown title="Download" menuVariant="dark">
              <NavDropdown.Item as={Link} exact='true' to="/download/protein">Single Protein Data</NavDropdown.Item>
              <NavDropdown.Item as={Link} exact='true' to="/download/bulk">Bulk Protein Data</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
