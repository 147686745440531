import React from 'react';
import ReactTooltip from 'react-tooltip';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export const DEFAULT_CELL_WIDTH = "10px";
export const DEFAULT_CELL_HEIGHT = "10px";

/*
  HTML div-based heatmap (slow rendering, but allows
  flexbox to wrap heatmap to multiple lines)
  TODO:
  - dynamic coloring mapping from data to color
  - add styling properties (height, width, margin)
  - dynamic rendering of labels
  - allow selection of rows and columns, render highlight
*/
export default function InteractiveHeatMap({
  data,
  xLabels,
  yLabels,
  colorMap,
  labelMap,
  cellWidth,
  cellHeight,
  handleCellClick,
  selectedCell,
  cellSelectionStyle
}) {
  if (!cellWidth) {
    cellWidth = DEFAULT_CELL_WIDTH;
  }

  if (!cellHeight) {
    cellHeight = DEFAULT_CELL_HEIGHT;
  }

  if (!cellSelectionStyle) {
    // cellSelectionStyle = "2px solid #E8B10C";
    cellSelectionStyle = "1px solid white";
  }

  // compute fixed width of x-axis labels so doesn't jitter
  // e.g. when switching to bold rendering for highlighting
  const labelLengthMax = Math.max(...xLabels.map(l => l.length));
  const xLabelHeight = (labelLengthMax * 10) + "px";

  const renderColumnBase = (i, columnContent, xLabel) => {
    let paddingLeft = '0px';
    let paddingRight = '0px';
    let align = 'center';
    if (i == '0') {
      paddingLeft = '20px';
      paddingRight = '10px';
      align = 'bottom';
    }
    return (
      <div
        key={"xlabel_" + i}
        style={{
          display: "flex",
          flexDirection: "column",
          width: cellWidth,
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
        }}
      >
        {columnContent}
        <div
          style={{
            fontSize: 'xx-small',
            display: "flex",
            justifyContent: "flex-end",
            alignItems: align,
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            MozBoxSizing: 'border-box',
            WebkitBoxSizing: 'border-box',
            boxSizing: 'border-box',
          }}
        >
          {xLabel && '-'}
        </div>
        <div
          style={{
            fontSize: 'xx-small',
            height: xLabelHeight,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: align,
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            MozBoxSizing: 'border-box',
            WebkitBoxSizing: 'border-box',
            boxSizing: 'border-box',
          }}
        >
          {xLabel}
        </div>
      </div>
    );
  };

  const renderYLabels = () => {
    if (yLabels) {
      const yLabelContent = yLabels.map((yLabel, j) => {
        return (
          <div
            key={"ylabel_" + j}
            style={{
              fontSize: 'xx-small',
              height: cellHeight,
              width: cellWidth,
              lineHeight: cellHeight,
              textAlign: "center",
              horizontalAlign: "middle",
              writingMode: "vertical-lr",
              transform: "rotate(180deg)",
              cursor: "default"
            }}
          >
            {yLabel}
          </div>
        );
      });
        const yTickContent = yLabels.map((yLabel, j) => {
          return (
            <div
              key={yLabel + "_" + j}
              style={{
                fontSize: 'xx-small',
                height: cellHeight,
                width: cellWidth,
                lineHeight: cellHeight,
                textAlign: "right",
                horizontalAlign: "middle",
                cursor: "default"
              }}
            >
              {'-'}
            </div>
          );
      });

      return (
        <>
          <div
            key={"xlabel_" + '-2'}
            style={{
              display: "flex",
              flexDirection: "column",
              width: cellWidth,
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: 100,
            }}
          >
            {yLabelContent}
            <div
              style={{
                fontSize: 'xx-small',
                width: cellWidth,
                height: (labelLengthMax * 8) + "px",
                textAlign: "center",
                horizontalAlign: "middle",
                cursor: "default",
              }}
            >
              {}
            </div>
          </div>
          <div
            key={"xlabel_" + '-1'}
            style={{
              display: "flex",
              flexDirection: "column",
              width: cellWidth,
              position: 'absolute',
              marginLeft: cellWidth,
              backgroundColor: 'white',
              zIndex: 100,
            }}
          >
            {yTickContent}
            <div
              style={{
                fontSize: 'xx-small',
                width: cellWidth,
                height: (labelLengthMax * 8) + "px",
                textAlign: "right",
                horizontalAlign: "middle",
                cursor: "default",
              }}
            >
              {}
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderDataColumn = (column, i) => {
    const columnContent = column.map((cellValue, j) => (
      <div
        key={i + "_" + j}
        onClick={event => {
          // console.log("HANDLE ENTRY CLICK", i, j, cellValue);
          if (handleCellClick) {
            handleCellClick(i, j, cellValue);
          }
          event.stopPropagation();
        }}
        // note data-tip must be a string, so serialize object
        data-tip={JSON.stringify({ i: i, j: j })}
        data-for="heatmapLabel"
        style={{
          height: cellHeight,
          width: cellWidth,
          textAlign: "justify",
          backgroundColor: colorMap(cellValue),
          border:
            selectedCell && i == selectedCell['i'] && j == selectedCell['j']
              ? cellSelectionStyle
              : null
        }}
      />
    ));
    return renderColumnBase(i, columnContent, xLabels[i]);
  };

  return (
    <>
      <ReactTooltip
        id="heatmapLabel"
        getContent={dataTip => (dataTip ? labelMap(JSON.parse(dataTip)) : null)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          justifyContent: "top",
        }}
      >
        {renderYLabels()}
        {data.map((column, i) => renderDataColumn(column, i))}
      </div>
    </>
  );
};
