import { DataFrame } from "data-forge";

export const roundWithSigs = (numAsString, sigFigs) => {
  if (!numAsString) {
    return '';
  }
  const maxDisplayLengthBeforeExpNotation = 5;
  const roundedWithSigs = Number.parseFloat(numAsString).toPrecision(sigFigs);
  if (roundedWithSigs.length > maxDisplayLengthBeforeExpNotation) {
    return Number.parseFloat(roundedWithSigs).toExponential(2);
  }
  return roundedWithSigs;
}

export const reorderValues = (x, targetColumn, order, reverse) => {
  // const ordered = Array(x.length);
  // note this needs to be based on order rather than data, since self-mutant not in data table
  const ordered = Array(order.length).fill(0);
  x.forEach(r => {
    // note that position mapping is inefficient and should rather be done with a map
    // console.log(r.subs, order.indexOf(r.subs));
    const idx = reverse
      ? order.length - order.indexOf(r.mt_aa) - 1
      : order.indexOf(r.mt_aa);
    ordered[idx] = r[targetColumn];
  });

  return ordered;
};

export const extractMutationData = (
  mutationTable,
  substitutionOrder,
  reverseOrder
) => {
  let positions = [];
  let wildtype = [];
  let dataMatrix = [];

  // TODO: make sure order of groupby is guaranteed
  // TODO: remove head
  const dataByPos = mutationTable.groupBy(row =>
    row.position
  );

  // go through sub-dataframe for each position
  dataByPos.forEach(x => {
    const xArr = x.toArray();

    positions.push(xArr[0].position);

    // store wildtype residue/base
    wildtype.push(xArr[0].wt_aa);

    // store current data column in right order of entries
    // reverse order so rendered in same way as by pipeline
    var tmp = reorderValues(
        xArr,
        "EVE_scores_ASM",
        substitutionOrder,
        reverseOrder
      );
    dataMatrix.push(
      tmp
    );
  });

  return {
    positions: positions,
    wildtype: wildtype,
    dataMatrix: dataMatrix,
  };
};

/*
  Wrapper that creates DataFrame independent
  of record/list orientation of data
  TODO: move to utils subpackage
*/
export const makeDataFrame = (data) => {
  // determine data orientation and create dataframe accordingly
  if (Array.isArray(data)) {
    return new DataFrame({values: data});
  } else {
    // dictionary of column lists
    return new DataFrame({ columns: data });
  }
};
