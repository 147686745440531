import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { AutoSizer } from "react-virtualized";
import DataTable from 'react-data-table-component';
import Row from 'react-bootstrap/Row';
import { Document, Page, pdfjs } from 'react-pdf/dist/umd/entry.webpack';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {
  proteinScatterAndViolinPlotsUrl,
  proteinScatterAndViolinLegendUrl,
  proteinStatsUrl
} from '../../routes';
import { proteinScatterAndViolinPlotsBlurb, proteinSummaryStatsBlurb } from '../../blurbs.jsx';
import { roundWithSigs } from '../../utils';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

const useStyles = makeStyles({
  root: {
    margin: '20px',
  },
  blurb: {
    padding: '10px 30px',
    whiteSpace: 'pre-line',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default function ProteinFootballCard(props) {
  const classes = useStyles();
  const proteinId = props.proteinId;
  const [scatterViolinUrl, setScatterViolinUrl] = useState('');
  const [proteinStats, setProteinStats] = useState({});
  const [proteinStatsTableData, setProteinStatsTableData] = useState([]);
  const [proteinStatsTableColumns, setProteinStatsTableColumns] = useState([]);

  useEffect(async () => {
    if (proteinId < 0) { return }
    setScatterViolinUrl(proteinScatterAndViolinPlotsUrl(proteinId));

    const fetchedProteinStats = await fetch(proteinStatsUrl(proteinId))
    .then((response) => {
      return response.json()
    });
    setProteinStats(fetchedProteinStats);
  }, [proteinId]);

  useEffect(() => {
    const columnsForTable = [
      {
        name: proteinId,
        selector: 'property',
      },
      {
        selector: 'value',
        right: true
      }
    ]
    setProteinStatsTableColumns(columnsForTable);
    // We need both a list and a map to ensure order of properties displayed
    const properties = [
      'bp_count_cov',
      'AUC',
      'accuracy_all',
      'accuracy_75pct'
    ];
    const propertyDisplayNames = {
      'bp_count_cov': 'Number of Labels',
      'AUC': 'EVE AUC (All Labels)',
      'accuracy_all': 'EVE Accuracy (All Labels)',
      'accuracy_75pct': 'EVE Accuracy (75% Set)'
    };
    const propertiesNeedingRounding = new Set([
      'AUC',
      'accuracy_all',
      'accuracy_75pct',
    ]);
    let data = [];
    // Note use of `of` instead of `in` to iterate in order
    for (const property of properties) {
      if (propertiesNeedingRounding.has(property)) {
        data.push({'property': propertyDisplayNames[property], 'value': roundWithSigs(proteinStats[property], 3)});
      }
      else {
        data.push({'property': propertyDisplayNames[property], 'value': proteinStats[property]});
      }
    }
    setProteinStatsTableData(data);
  }, [proteinStats]);

  return (
    <>
      <Row className={classes.centered}>
        <Col>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Document
                file={scatterViolinUrl}
                className={classes.root}
              >
                <Page pageNumber={1} width={width*.85}/>
              </Document>
            )}
          </AutoSizer>
        </Col>
        <Col md="6">
          <Paper variant="outlined" square className={classes.root}>
            <DataTable
              columns={proteinStatsTableColumns}
              data={proteinStatsTableData}
              noHeader
              noTableHead
              style={{height: '90%'}}
            />
          </Paper>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Row className={classes.centered}>
            <Document
              file={proteinScatterAndViolinLegendUrl}
            >
              <Page pageNumber={1} width={300}/>
            </Document>
          </Row>
          <div className={classes.blurb}>
            {proteinScatterAndViolinPlotsBlurb()}
          </div>
        </Col>
        <Col md="6">
          <div className={classes.blurb}>
            {proteinSummaryStatsBlurb()}
          </div>
        </Col>
      </Row>
    </>
  )
}
