import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { homePageContactUsBlurb } from '../../blurbs.jsx';
import ProteinSearchBar from '../ProteinSearchBar';

export default function SearchPage() {
  return (
      <Container style={{ paddingTop: 40 }}>
        <Row>
          <h3>Search</h3>
        </Row>
        <Row>
          <p>Look up a gene below to view and download the dataset for that specific gene!</p>
        </Row>
        <Row style={{ paddingTop: 40 }}>
          <ProteinSearchBar />
        </Row>
        <Row style={{alignItems: 'center', justifyContent: 'center', paddingTop: '2em'}}>
          <p style={{textAlign: 'center'}}>
            {homePageContactUsBlurb()}
          </p>
        </Row>
      </Container>
  );
}
