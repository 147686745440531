import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DataTable from 'react-data-table-component';
import Row from 'react-bootstrap/Row';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {
  heatMapBlurb,
  proteinPositionsVariantsTableBlurb,
} from '../../blurbs';

import {
  proteinDataDownloadUrl,
  proteinPositionVariantsCountUrl,
  proteinPositionVariantsHeatmapUrl,
  proteinPositionVariantsTableUrl,
  proteinPositionVariantsUrl,
  proteinWebPidIdUrl,
} from '../../routes';

import MutationPanel from '../MutationPanel';
import ProteinFootballCard from '../ProteinFootballCard';
import ProteinPositionVariantsTable from '../ProteinPositionVariantsTable';
import { makeDataFrame } from '../../utils';

const useStyles = makeStyles({
  anchor: {
    color: 'dark blue',
  },
  containerDefault: {
    paddingTop: '40px',
    maxWidth: '90%',
  },
  heatMapContainer: {
    maxWidth: '90%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heatMapBlurbContainer: {
    maxWidth: '90%',
    textAlign: 'center',
    paddingBottom: '30px'
  },
  heatMapGradientContainer: {
    maxWidth: '30%',
    paddingBottom: '10px'
  },
  heatMapGradient: {
    background: 'linear-gradient(90deg, rgba(143,179,255,1) 0%, rgba(219,219,219,1) 40%, rgba(219,219,219,1) 60%, rgba(220,0,0,1) 100%)',
    height: '20px',
    width: 'auto',
  },
  heatMapGradientBlurbRow: {
    fontSize: 'x-small',
  },
  heatMapGradientBlurbCol: {
    padding: '0px',
    whiteSpace: 'pre',
  },
  heatMapGradientBlurbSpan: {
    float: 'right',
  },
  footballCardContainer: {
    padding: '0 20px 40px 20px',
  },
  proteinVariantsTableRow: {
    padding: '20px 20px 0 20px',
  },
  proteinVariantsTableCol: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  proteinVariantsTableBlurb: {
    padding: '0 20px 20px 20px',
  },
});

export default function SingleProteinPage() {
  const { proteinWebPid } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [variantData, setVariantData] = useState([]);
  const [mutationDataFrame, setMutationDataFrame] = useState(null);
  const [proteinId, setProteinId] = useState(-1);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const downloadOnClick = () => {
    history.push(`/download/protein/${proteinWebPid}`);
  }

  useEffect(() => {
    const fetchProteinId = async () => {
      const fetchedProteinId = await fetch(proteinWebPidIdUrl(proteinWebPid))
      .then((response) => {
        if (response.status === 404) {
          history.push(`/404`);
        }
        return response.json();
      });
      setProteinId(fetchedProteinId);
    };
    fetchProteinId();
  }, [proteinWebPid, history]);

  useEffect(() => {
    const fetchVariantTableData = async () => {
      setLoadingTableData(true);
      if (proteinId < 0) { return }
      const offset = 0;
      const limit = -1;
      const fetchedVariantData = await fetch(`${proteinPositionVariantsTableUrl(proteinId, offset, limit)}`)
      .then((response) => response.json());

      setVariantData(fetchedVariantData);
      setLoadingTableData(false);
    };
    fetchVariantTableData();
  }, [proteinId]);

  useEffect(() => {
    const fetchVariantHeatmapData = async () => {
      if (proteinId < 0) { return }
      const offset = 0;
      const limit = -1;
      const fetchedVariantData = await fetch(`${proteinPositionVariantsHeatmapUrl(proteinId, offset, limit)}`)
      .then((response) => response.json());

      setMutationDataFrame(makeDataFrame(fetchedVariantData));
    };
    fetchVariantHeatmapData();
  }, [proteinId]);

  return (
    <>
      <Container className={classes.containerDefault}>
        <Row className="d-flex">
          <Col md="4">
            <h3>{proteinWebPid}</h3>
            <a className={classes.anchor} href="#heatMapContainer">Heatmap</a>
            <span> | </span>
            <a className={classes.anchor} href="#variantsTableContainer">Variants Table</a>
            <span> | </span>
            <a className={classes.anchor} href="#footballCardContainer">Statistics Summary</a>
          </Col>
          <Col md={{ span: 2, offset: 6 }}>
            <Button variant="primary" onClick={ downloadOnClick } style={{ float: 'right' }}>
              Download Data
            </Button>
          </Col>
        </Row>
        <hr/>
      </Container>
      <div style={{paddingTop: '20px'}}>
        <Container className={classes.heatMapContainer} id='heatMapContainer'>
          <MutationPanel mutationDataFrame={mutationDataFrame ? mutationDataFrame : null} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant}/>
        </Container>
        <Container className={classes.heatMapGradientContainer}>
          <Row className={classes.heatMapGradientBlurbRow}>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span style={{float: 'left'}}>{'Benign'}</span>
              <span className={classes.heatMapGradientBlurbSpan}>{'Pathogenic'}</span>
            </Col>
          </Row>
          <Row className={classes.heatMapGradient}/>
          <Row className={classes.heatMapGradientBlurbRow}>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'|  '}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'|  '}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'|  '}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'|  '}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{''}</span>
            </Col>
          </Row>
          <Row className={classes.heatMapGradientBlurbRow}>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'0.2'}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'0.4'}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'0.6'}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
              <span className={classes.heatMapGradientBlurbSpan}>{'0.8'}</span>
            </Col>
            <Col className={classes.heatMapGradientBlurbCol}>
            </Col>
          </Row>
        </Container>
        <Container className={classes.heatMapBlurbContainer}>
          {heatMapBlurb()}
        </Container>
      </div>
      <Container className={classes.containerDefault} id='variantsTableContainer'>
        <Paper variant="outlined">
          <Row className={classes.proteinVariantsTableRow}>
            <Col className={classes.proteinVariantsTableCol}>
              <Paper variant="outlined" square>
                <ProteinPositionVariantsTable proteinId={proteinId} variantData={variantData} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} loading={loadingTableData}/>
              </Paper>
            </Col>
          </Row>
          <br/>
          <Row className={classes.proteinVariantsTableBlurb}>
            <Col>
              {proteinPositionsVariantsTableBlurb()}
            </Col>
          </Row>
        </Paper>
        <br/>
      </Container>
      <Container className={classes.footballCardContainer} id='footballCardContainer'>
        <Row>
          <Col md="auto">
            <h5>Statistics Summary</h5>
          </Col>
        </Row>
        <Paper variant="outlined">
          <ProteinFootballCard proteinId={proteinId}/>
        </Paper>
      </Container>
    </>
  )
}
