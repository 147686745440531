import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ReactGA from 'react-ga';
import Row from 'react-bootstrap/Row';
import { useHistory, useParams } from 'react-router-dom';

import ProteinSelectBar from '../ProteinSelectBar';

import {
  licenseBlurb,
} from '../../blurbs';

import {
  proteinDataDownloadByWebPidUrl,
  proteinWebPidIdUrl,
} from '../../routes';

const useStyles = makeStyles({
  bottomRow: {
    paddingBottom: '40px'
  },
  containerDefault: {
    paddingTop: '40px',
  },
  row: {
    paddingTop: '40px',
  },
});

export default function ProteinDownloadPage() {
  const { proteinWebPid } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const downloadables = [
    {
      'name': 'Variants',
      'query_string': 'variants=True',
    },
    {
      'name': 'MSAs',
      'query_string': 'msas=True',
    },
    {
      'name': 'VCFs',
      'query_string': 'vcfs=True',
    },
    {
      'name': 'Performance Curves',
      'query_string': 'prc=True&roc=True',
    },
    {
      'name': 'Mutation Heatmap',
      'query_string': 'heatmap=True',
    }
  ];

  const [checkedStates, setCheckedStates] = useState(
    new Array(downloadables.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedStates = checkedStates.map((downloadable, index) =>
      index === position ? !downloadable : downloadable
    );
    setCheckedStates(updatedCheckedStates);
  }

  const checkProteinWebPidValidity = async () => {
    let valid = true;
    await fetch(proteinWebPidIdUrl(proteinWebPid))
    .then((response) => {
      if (response.status === 404) {
        valid = false;
      }
    })
    return valid;
  };

  const downloadCsv = async () => {
    if (!proteinWebPid) {
      alert("Please select a protein first to download its data.");
      return;
    } else {
      const valid = await checkProteinWebPidValidity();
      if (!valid) {
        alert("Please select a valid protein first to download its data.");
        return;
      }
    }
    const downloadBaseUrl = proteinDataDownloadByWebPidUrl(proteinWebPid);
    var downloadUrl = downloadBaseUrl + '?';
    var firstQueryParamSet = false;
    for (var index = 0; index < checkedStates.length; index++) {
      if (checkedStates[index]) {
        if (firstQueryParamSet) {
          downloadUrl = downloadUrl + '&'
        } else {
          firstQueryParamSet = true;
        }
        downloadUrl = downloadUrl + downloadables[index]['query_string'];
      }
    }
    if (!firstQueryParamSet) {
      alert("Please select one or more data files to download.");
      return;
    }
    window.open(downloadUrl);
    ReactGA.event({
      category: 'User',
      action: 'Downloaded Single Protein Data',
      label: `${proteinWebPid}`
    });
  }

  return (
      <>
        <Container className={classes.containerDefault}>
          <Row>
            <h1>Download Single Protein Data</h1>
          </Row>
          <Row>
            <p>
              Select below which protein and how much of its data you'd like to download!
              <br/>
              Alternatively, you can also download our entire <a href="/download/bulk"> bulk dataset.</a>
              <br/>
              <br/>
              <b>Notice:</b> on Nov 8, 2021 the EVE dataset was updated to include some missing VCFs. If you downloaded any data before this date, and the VCF was missing, please consider re-downloading the dataset.
            </p>
          </Row>
          <Row>
            <ProteinSelectBar />
          </Row>
        </Container>
        <Container style={{ maxWidth: '70%', paddingTop: 40 }}>
          <hr/>
        </Container>
        <Container className={classes.containerDefault}>
          <Row>
            {
              proteinWebPid ? <h3>{proteinWebPid}</h3> : <h3><br/></h3>
            }
          </Row>
          <Row className="justify-content-md-center align-items-center">
            <p>
              <br/>
              <b>
                Choose what data you'd like to download for this protein:
              </b>
              <br/>
            </p>
          </Row>
          <Row className="justify-content-md-center align-items-center">
            {downloadables.map(({ name }, index) => {
              return (
                <Col md="auto" key={index}>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={name}
                    value={name}
                    checked={checkedStates[index]}
                    onChange={() => handleOnChange(index)}
                  />
                  <label htmlFor={`custom-checkbox-${index}`} style={{ paddingLeft: 5 }}>{name}</label>
                </Col>
              );
            })}
          </Row>
          <Row className="justify-content-md-center align-items-center" style={{ paddingTop: 40 }}>
            <Col md="auto">
              <Button variant="primary" onClick={ downloadCsv }>
                Download Protein Data
              </Button>
            </Col>
          </Row>
          <Row className={`${classes.bottomRow} justify-content-md-center align-items-center fixed-bottom`}>
            <p>
              { licenseBlurb() }
            </p>
          </Row>
        </Container>
      </>
  );
}
