import React from 'react';
import ReactGA from 'react-ga';

import './App.css';
import Main from './components/Main';
import NavigationBar from './components/NavigationBar';
import RouteChangeTracker from './components/RouteChangeTracker';

const TRACKING_ID = 'UA-207363677-1';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className='app'>
        <NavigationBar />
        <Main />
        <RouteChangeTracker />
    </div>
  );
}

export default App;
