import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { proteinsListIdentifiersUrl } from '../../routes';

const ProteinsContext = React.createContext({
  proteinNames: [], fetchProteinNames: () => {}
})

const useStyles = makeStyles({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function ProteinSearchBar(props) {
  const [proteinList, setProteinList] = useState([]);
  const [proteinDict, setProteinDict] = useState({});
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const handleOnClick = () => {
    // Use a second tracking variable to avoid async waiting
    let matchedValue = value;
    if (matchedValue in proteinDict) {
      const matchedProteinWebPid = proteinDict[inputValue]['protein_symbol'];
      history.push(`/proteins/${matchedProteinWebPid}`);
    }
    // If there was no match, do nothing
    return false;
  }

  const fetchProteinList = async () => {
    const response = await fetch(`${proteinsListIdentifiersUrl}`);
    let proteinIdentifiersResponse = await response.json();
    var fetchedProteinList = [];
    var fetchedProteinDict = {};
    for (const proteinIdentifiers of proteinIdentifiersResponse) {
      const displayName = `${proteinIdentifiers['gene_symbol']} (${proteinIdentifiers['protein_symbol']}, ${proteinIdentifiers['uniprot_id']})`
      fetchedProteinList.push(displayName);
      fetchedProteinDict[displayName] = proteinIdentifiers;
    }
    setProteinList(fetchedProteinList);
    setProteinDict(fetchedProteinDict);
  }
  useEffect(() => {
    fetchProteinList()
  }, [])

  return (
    <ProteinsContext.Provider value={{proteinList, fetchProteinList}}>
      <Container>
        <Row className={ classes.centered }>
          <Col xs={8} sm={6} md={4}>
            <Autocomplete
              freeSolo
              value={value}
              onChange={ (event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={ (event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="protein-search-bar"
              options={ proteinList }
              renderInput={
                (params) => {
                  return <TextField {...params}
                    InputLabelProps={{
                      style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '80%',
                      }
                    }}
                    label="Search by gene symbol or uniprot id" variant="outlined"
                  />
                }
              }
            />
          </Col>
          <Col xs={1} sm={1} md={1}>
            <Button
              variant="primary"
              onClick={ handleOnClick }
            >
              Go
            </Button>
          </Col>
        </Row>
      </Container>
    </ProteinsContext.Provider>
  )
}
