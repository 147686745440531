import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { makeStyles } from '@material-ui/core/styles';

import ProteinSearchBar from '../ProteinSearchBar';
import { homePageBlurb, homePageContactUsBlurb, homePageSideBarBlurb } from '../../blurbs.jsx';
import funderLogos from './funder_logos.jpeg';
import { ReactComponent as EveLogoSVG } from './eveLogo.svg';

const useStyles = makeStyles({
  container: {
    maxWidth: '70%',
    paddingTop: 75,
  },
  centeredRow: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurbCol: {
    maxWidth: '90%',
  },
  topPadding: {
    paddingTop: 30,
  },
  topPaddingExtra: {
    paddingTop: 70,
  },
});

export default function HomePage() {
  const classes = useStyles();
  return (
      <Container className={classes.container}>
        <Row className={classes.centeredRow}>
          <Col xs={12} sm={10} md={8} lg={7} xl={4}>
            <EveLogoSVG/>
          </Col>
        </Row>
        <Row>
          <ProteinSearchBar />
        </Row>
        <Row className={ `${classes.centeredRow} ${classes.topPadding}` } style={{ textAlign: 'center' }}>
          {homePageContactUsBlurb()}
        </Row>
        <Row className={ `${classes.centeredRow} ${classes.topPaddingExtra}` }>
          <Col className={ classes.blurbCol }>
            {homePageBlurb()}
          </Col>
        </Row>
        <Row className={ `${classes.centeredRow} ${classes.topPaddingExtra }` } style={{ paddingBottom: 50 }}>
          <img src={funderLogos} alt="FunderLogos" style={{ maxWidth: '90%', maxHeight: '100px' }}/>
        </Row>
      </Container>
  );
}
