import React, { useState, useMemo, useEffect } from 'react';
import { interpolateRgb, color, rgb } from 'd3';
import InteractiveHeatMap from '../InteractiveHeatMap';
import Spinner from 'react-bootstrap/Spinner';

import { extractMutationData, roundWithSigs } from '../../utils';

const AA_LIST_PROPERTY = 'WFYPMILVAGCSTQNDEHRK';
const ALMOST_EQUAL = 1e-12;
const WHITE = rgb(255, 255, 255);
const LIGHT_GREY = rgb(219, 219, 219);
const RED = rgb(220, 0, 0);
const BLUE = rgb(143, 179, 255);

/*
  Create color mapping function for defined value range
*/
const createColorMapper = (
  data,
) => {
  let minVal;
  let maxVal;

  if (Array.isArray(data)) {
    [minVal, maxVal] = data;
  } else {
    // dataforge series
    if (data.count() > 0) {
      minVal = data.min();
      maxVal = data.max();
    } else {
      throw new Error("Series 'data' does not contain any values.");
    }
  }

  const rangeMin = minVal;
  const rangeMax = maxVal;

  if (Math.abs(rangeMin - rangeMax) < ALMOST_EQUAL) {
    throw new Error(
      `Upper and lower boundary (${rangeMin} and ${rangeMax} respectively) are the same, which would lead to division by zero.`
    );
  }

  return (v) => {
    // symmetrical case
    // var mappedVal = (v + range) / (2 * range);
    var mappedVal = (v - rangeMin) / (rangeMax - rangeMin);

    if (!mappedVal) {
      return WHITE.hex();
    }

    let c;
    if (mappedVal < 0.5) {
      c = color(interpolateRgb.gamma(3)(BLUE, LIGHT_GREY)(mappedVal*2));
    } else {
      c = color(interpolateRgb.gamma(3)(LIGHT_GREY, RED)((mappedVal-0.5)*2));
    }

    return c.hex()
  };
};

export default function MutationPanel(props) {
  const [cellSize, setCellSize] = useState(10);
  const mutationDataFrame = props.mutationDataFrame;
  const [selectedCell, setSelectedCell] = useState(null);
  const selectedVariant = props.selectedVariant;
  const setSelectedVariant = props.setSelectedVariant;

  const substitutionOrder = AA_LIST_PROPERTY;
  const reverseSubstitutionOrder = true;

  let yLabels = Array.from(AA_LIST_PROPERTY);
  if (reverseSubstitutionOrder) {
    yLabels.reverse();
  }

  useEffect(() => {
    const variantChanged = () => {
      if (selectedVariant == null) {
        return;
      }
      const x = selectedVariant['pos'] - 1;
      const y = yLabels.indexOf(selectedVariant['mt_aa']);
      setSelectedCell({
        'i': x,
        'j': y,
      })
      setSelectedVariant(selectedVariant);
    };
    variantChanged();
  }, [selectedVariant]);

  let extractedData;
  let siteIds;

  if (mutationDataFrame) {
    extractedData = extractMutationData(
      mutationDataFrame,
      substitutionOrder,
      reverseSubstitutionOrder
    );

    // create site IDs for all positions in matrix for selection handling
    siteIds = extractedData.positions.map((pos, i) => {
      const DEFAULT_SEGMENT_ID = 'A';
      const createSiteId = (segment, pos) => segment + '__' + pos;
      return createSiteId(
        DEFAULT_SEGMENT_ID, pos
      );
    });
  }

  // colormap for heatmap rendering;
  // memoize for structure colormap update below
  const curColorMapper = useMemo(() => {
    return mutationDataFrame
      ? createColorMapper(
          mutationDataFrame.getSeries('EVE_scores_ASM'),
        )
      : null;
  }, [mutationDataFrame]);

  const renderHeatmap = () => {
    // TODO: cache component to avoid expensive rerenders with lots of divs?
    let fullContent;

    // TODO: add segment display (if selected)
    const xLabels = extractedData.positions.map(
      (pos, i) => extractedData.wildtype[i] + ' ' + pos
    );

    const dataMatrix = extractedData.dataMatrix;

    // TODO: probably nicer to have all selection logic below in reducer
    const heatmapContent = (
      <InteractiveHeatMap
        data={dataMatrix}
        xLabels={xLabels}
        yLabels={yLabels}
        selectedCell={selectedCell}
        cellWidth={cellSize + 'px'}
        cellHeight={cellSize + 'px'}
        colorMap={curColorMapper}
        labelMap={({ i, j }) => {
          const subs =
            substitutionOrder[
              reverseSubstitutionOrder ? AA_LIST_PROPERTY.length - j - 1 : j
            ];
          return (
            <span style={{zIndex: 200}}>
              Mutant: <b>{xLabels[i] + ' ' + subs}</b>
              <br />
              EVE Score: <b>{dataMatrix[i][j] ? roundWithSigs(dataMatrix[i][j], 3) : 'n/a'}</b>
            </span>
          );
        }}
        handleCellClick={(i, j, cellValue) => {
          var [wt_aa, pos] = xLabels[i].split(' ');
          const mt_aa = yLabels[j];
          if (selectedCell && selectedCell['i'] == i && selectedCell['j'] == j) {
            setSelectedVariant(null);
            setSelectedCell(null);
          } else {
            setSelectedVariant({
              'wt_aa': wt_aa,
              'pos': pos,
              'mt_aa': mt_aa
            });
          }
        }}
      />
    );

    fullContent = (
      <>
        <div
          style={{
            overflow: 'auto',
            overflowX: 'hidden'
            // need to have overflowY if heatmap is too big too display on viewport
          }}
        >
          {heatmapContent}
        </div>
      </>
    );

    // TODO: clean up CSS mess, reuse this globally for all components
    return (
      <div
        style={{
          // div resizing
          overflow: 'auto',

          // border
          borderWidth: '1pt',
          borderStyle: 'solid',
          borderColor: '#efefef',

          // margins
          marginBottom: '1em',

          // disable scroll bars in Safari
          position: 'relative',
        }}
      >
        {fullContent}
      </div>
    );
  };

  return (
    <>
      { mutationDataFrame ?
        renderHeatmap() :
          <div
            style={{
              height: '250px',
              width: '100%',
              justifyContent: 'center',
              position: 'relative',
              borderWidth: '1pt',
              borderStyle: 'solid',
              borderColor: '#efefef',
              marginBottom: '1em',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)'
              }}
            >
              <Spinner animation="border" role="loading">
              </Spinner>
            </div>
          </div>
      }
    </>
  );
};
