import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Col';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { AutoSizer, Column, Table } from "react-virtualized";
import Spinner from 'react-bootstrap/Spinner';
import './styles.css';
import clsx from 'clsx';

import { roundWithSigs } from '../../utils';

const useStyles = makeStyles({
  headerRow: {
    borderBottom: '1px solid #e0e0e0',
  },
  evenRow: {
    borderBottom: '1px solid #e0e0e0',
    '&:hover, &:focus': {
      backgroundColor: '#e0e0e0'
    },
  },
  oddRow: {
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
    '&:hover, &:focus': {
      backgroundColor: '#e0e0e0'
    },
  },
});

export default function ProteinPositionVariantsTable(props) {
  const classes = useStyles();
  const proteinId = props.proteinId;
  const loading = props.loading;
  const proteinTableData = props.variantData;
  const selectedVariant = props.selectedVariant;
  const setSelectedVariant = props.setSelectedVariant;
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
  const [widths, setWidths] = useState({
      'wt_aa': 0.07,
      'position': 0.07,
      'mt_aa': 0.07,
      'EVE_scores_ASM': 0.15,
      'EVE_classes_75_pct_retained_ASM': 0.15,
      'uncertainty_ASM': 0.15,
      'frequency_gv2': 0.17,
      'ClinVar_ClinicalSignificance': 0.17
  });
  const TOTAL_WIDTH = 1500;

  useEffect(() => {
    const selectedVariantChanged = () => {
      if (selectedVariant) {
        // Fast compute hack
        const aaListAlphabetized = 'ACDEFGHIKLMNPQRSTVWY';
        const index = 20*(selectedVariant['pos']-1) + aaListAlphabetized.indexOf(selectedVariant['mt_aa']);
        setSelectedVariantIndex(index);
      }
    };
    selectedVariantChanged();
  }, [selectedVariant]);

  // Format numeric data where needed
  const columnsWithNumbers = [
    'frequency_gv2',
    'EVE_scores_ASM',
    'uncertainty_ASM',
  ];
  for (const row of proteinTableData) {
    for (const col of columnsWithNumbers) {
      row[col] = roundWithSigs(row[col], 3);
    }
  };

  const columnKeys = [
    'wt_aa',
    'position',
    'mt_aa',
    'EVE_scores_ASM',
    'EVE_classes_75_pct_retained_ASM',
    'uncertainty_ASM',
    'frequency_gv2',
    'ClinVar_ClinicalSignificance',
  ];

  const columnKeyDisplayNames = {
    'wt_aa': 'Reference aa',
    'position': 'Position',
    'mt_aa': 'Variant aa',
    'EVE_scores_ASM': 'EVE Score',
    'EVE_classes_75_pct_retained_ASM': 'EVE Class (75% Set)',
    'uncertainty_ASM': 'Class Uncertainty',
    'frequency_gv2': 'Frequency (gnomAD v2)',
    'ClinVar_ClinicalSignificance': 'ClinVar Label',
  };

  const _rowClassName = ({index}) => {
    if (index < 0) {
      return classes.headerRow;
    } else {
      return index % 2 === 0 ? classes.evenRow : classes.oddRow;
    }
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        loading
        ?
          <div
            style={{
              height: '500px',
              width: width,
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)'
              }}
            >
              <Spinner animation="border" role="loading">
              </Spinner>
            </div>
          </div>
        :
        <Table
          width={width}
          height={500}
          headerHeight={50}
          onRowClick={({ event, index, rowData }) => {
            setSelectedVariant({
              'wt_aa': proteinTableData[index]['wt_aa'],
              'pos': proteinTableData[index]['position'],
              'mt_aa': proteinTableData[index]['mt_aa'],
            })
          }}
          rowHeight={50}
          rowCount={proteinTableData.length}
          rowClassName={_rowClassName}
          rowGetter={({ index }) => proteinTableData[index]}
          scrollToAlignment={'start'}
          scrollToIndex={selectedVariantIndex}
        >
          {
            columnKeys.map((key) => {
              return (
                <Column
                  key={key}
                  dataKey={key}
                  label={columnKeyDisplayNames[key]}
                  width={widths[key] * width}
                />
              );
            })
          }
        </Table>
      )}
    </AutoSizer>
  );
}
