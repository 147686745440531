const baseUrl = (process.env.REACT_APP_BACKEND_BASE_URL ? process.env.REACT_APP_BACKEND_BASE_URL : '')


export const baseProteinsUrl = `${baseUrl}/api/proteins/`;
export const proteinsListUrl = `${baseUrl}/api/proteins/list/`;
export const proteinsListIdentifiersUrl = `${proteinsListUrl}identifiers/`;
export const proteinScatterAndViolinLegendUrl = `${baseProteinsUrl}scatter_violin_legend/`;
export const proteinBulkDataDownloadUrl = () => {
  return `${baseProteinsUrl}bulk/download/`
};

// By ID
export const baseProteinsByIdUrl = `${baseUrl}/api/proteins/id/`;
export const proteinPositionVariantsUrl = (proteinId, skip = 0, limit = -1) => {
  return `${baseProteinsByIdUrl}${proteinId}/protein_position_variants/?skip=${skip}&limit=${limit}`
};
export const proteinPositionVariantsTableUrl = (proteinId, skip = 0, limit = -1) => {
  return `${baseProteinsByIdUrl}${proteinId}/protein_position_variants/table_data?skip=${skip}&limit=${limit}`
};
export const proteinPositionVariantsHeatmapUrl = (proteinId, skip = 0, limit = -1) => {
  return `${baseProteinsByIdUrl}${proteinId}/protein_position_variants/heatmap_data?skip=${skip}&limit=${limit}`
};
export const proteinPositionVariantsCountUrl = (proteinId) => {
  return `${baseProteinsByIdUrl}${proteinId}/protein_position_variants/count/`
};
export const proteinScatterAndViolinPlotsUrl = (proteinId) => {
  return `${baseProteinsByIdUrl}${proteinId}/scatter_violin/`
};
export const proteinStatsUrl = (proteinId) => {
  return `${baseProteinsByIdUrl}${proteinId}/protein_stats/`
};
export const proteinDataDownloadUrl = (proteinId) => {
  return `${baseProteinsByIdUrl}${proteinId}/download/`
};

// By Name
export const proteinByNameUrl = `${baseProteinsUrl}name/`;
export const uniprotIdUrl = (proteinName) => {
  return `${proteinByNameUrl}${proteinName}/uniprot_id/`
};
export const proteinNameIdUrl = (proteinName) => {
  return `${proteinByNameUrl}${proteinName}/id/`
};
export const proteinDataDownloadByNameUrl = (proteinName) => {
  return `${proteinByNameUrl}${proteinName}/download/`
};

// By Web Pid
export const baseProteinsByWebPidUrl = `${baseUrl}/api/proteins/web_pid/`;
export const proteinWebPidIdUrl = (proteinWebPid) => {
  return `${baseProteinsByWebPidUrl}${proteinWebPid}/id/`
};
export const proteinDataDownloadByWebPidUrl = (proteinWebPid) => {
  return `${baseProteinsByWebPidUrl}${proteinWebPid}/download/`
};
