import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ReactGA from 'react-ga';
import Row from 'react-bootstrap/Row';

import {
  licenseBlurb,
} from '../../blurbs';

import {
  proteinBulkDataDownloadUrl,
} from '../../routes';

const useStyles = makeStyles({
  bottomRow: {
    paddingBottom: '40px'
  },
  containerDefault: {
    paddingTop: '40px',
  },
  row: {
    paddingTop: '40px',
  },
});

export default function BulkDownloadPage() {
  const classes = useStyles();

  const downloadBulkData = () => {
    const downloadUrl = proteinBulkDataDownloadUrl();
    window.open(downloadUrl);
    ReactGA.event({
      category: 'User',
      action: 'Downloaded Bulk Protein Data',
      label: 'Bulk Data'
    });
  }

  return (
      <Container className={classes.containerDefault}>
        <Row>
          <h3>Bulk Data Download</h3>
        </Row>
        <Row>
          <p>
            <br/>
            Click below to download all EVE data!
            <br/>
            Because the data will consist of all variant data, MSAs, VCFs, PRCs, and ROCs for each of the proteins that we have, it may take a while to download.
            <br/>
            <br/>
            <b>Notice:</b> on Nov 8, 2021 the bulk dataset was updated to include some missing VCFs. If you downloaded the bulk dataset before this date, please consider re-downloading the dataset.
          </p>
        </Row>
        <Row className={`${classes.row} justify-content-md-center align-items-center`}>
          <Button variant="primary" onClick={ downloadBulkData }>
            Download All Data
          </Button>
        </Row>
        <Row className={`${classes.bottomRow} justify-content-md-center align-items-center fixed-bottom`}>
          <p>
            { licenseBlurb() }
          </p>
        </Row>
      </Container>
  );
}
